import React, {useState} from "react";
import '../Components_SCSS/Component_Gallery.scss';

import Nav2 from "./nav2";

function ComponentGallery({img}) {

    const images = img
    const [currentIndex, setCurrentIndex] = useState(-1);

    const nextImage = () => {
        const nextIndex = (currentIndex + 1);
        setCurrentIndex(nextIndex);
    };

    const prevImage = () => {
        const prevIndex = (currentIndex - 1);
        setCurrentIndex(prevIndex);
    };

    const openImage = (index) => {
        setCurrentIndex(index);

    };

    const closeImage = () => {
        setCurrentIndex(-1);



    };

    const image = images[currentIndex];


    return (
        <>
            <Nav2/>

            <div className="galeria">
                <h3>Kliknij na zdjęcie aby przybliżyć</h3>
                <div className="gallery-row">
                    {images.map((img, index) => (
                        <div className="gallery-item" key={index}>
                            <img
                                src={img}
                                alt={`${index }`}
                                onClick={() => openImage(index)}
                            />
                            <div className="zoom-icon" onClick={() => openImage(index)}>
                                <i className="fa-solid fa-magnifying-glass-plus"></i>
                            </div>
                        </div>
                    ))}
                </div>
                {currentIndex > -1 && (
                    <div className="image-overlay">
                        <div className="image-viewer">
                            <img src={image} alt={`${currentIndex}`}/>
                            <div className="close-button" onClick={closeImage}>
                                &times;
                            </div>
                            {currentIndex > 0 && (
                                <div className="prev-button" onClick={prevImage}>
                                    &lt;
                                </div>
                            )}
                            {currentIndex < images.length - 2 && (
                                <div className="next-button" onClick={nextImage}>
                                    &gt;
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ComponentGallery;