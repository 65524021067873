import React from "react";
import '../Components_SCSS/Contact.scss';
import logo from '../Assets/logo_sowka.webp'

function Contact() {
    return (
        <section className='contact'>
            <div className='contact_container'>
                <div className="contact_container_contact">
                    <h1 className="contact_container_contact_heading">Dane kontaktowe</h1>
                    <p className="contact_container_contact_text">Skontaktuj się z nami. Jesteśmy tutaj dla Ciebie!</p>
                    <div className="contact_container_contact_box">
                        <div className="contact_container_contact_phone">
                            <i className="fas fa-phone"></i>
                            <a href="tel: 601-419-879">601-419-879</a></div>
                        <div className="contact_container_contact_phone">
                            <i className="fas fa-phone"></i>
                            <a href="tel: 535-519-309">535-519-309</a>
                        </div>
                        <div className="contact_container_contact_phone">
                            <i className="fas fa-phone"></i>
                            <div className="phone_restaurant">
                                <p>Wyłącznie zamówienia restauracyjne i pizza</p>
                                <a href="tel: 666-690-149">666-690-149</a>
                            </div>
                        </div>
                        <div className="contact_container_contact_email">
                            <i className="fas fa-envelope"></i>
                            <a href="mailto:txt@gmail.com">
                                <p>noclegi.pod.sowka@onet.pl</p>
                            </a>
                        </div>
                        <div className="contact_container_contact_address">
                            <i className="fas fa-map-marker-alt"></i>
                            <p>ul. 18-go Stycznia 20,<br></br> 09-500 Gostynin</p>
                        </div>
                    </div>
                </div>
                <div className="contact_container_map">
                    <iframe title='maps'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.317981837403!2d19.465838876675!3d52.41903587203489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b86d6a8a3988b%3A0x235acb932b3b4273!2zUGVuc2pvbmF0ICYgUmVzdGF1cmFjamEg4oCeUG9kIFPDs3drxIXigJ0!5e0!3m2!1spl!2spl!4v1697123477059!5m2!1spl!2spl"
                            className='contact_container_map_iframe' allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>

                </div>

            </div>
            <footer className='footer'>
                <div className="footer_container">
                    <div className="footer_container_left">
                        <div className="footer_container_left_logo">
                            <img src={logo} alt="Logo_sówka" className='footer_container_left_logo_img'/>
                            <h2>"Pod sówką"</h2>
                        </div>
                        <div className="footer_container_left_text">
                            <p>© 2023 All rights reserved</p>
                        </div>
                    </div>
                    <div className="footer_container_middle">
                        <div className="footer_container_middle_text">
                            <p className='middle_text_phone'>Telefon: <a href="tel: 601-419-879">601-419-879</a> lub <a
                                href="tel:535-519-309 ">535-519-309</a></p>
                            <p className='middle_text_email'>Email:
                                <a href="mailto:noclegi.pod.sowka@onet.pl"
                                   className='footer_container_middle_text_link'> noclegi.pod.sowka@onet.pl</a>
                            </p>
                            <p className='middle_text_address'>Ul. 18-go Stycznia 20,
                                09-500 Gostynin</p>
                        </div>
                    </div>
                    <div className="footer_container_right">
                        <div className="footer_container_right_social">
                            <div className="footer_container_right_social_facebook">
                                <a href="https://www.facebook.com/profile.php?id=100036109668690" target="_blank"
                                   rel="noreferrer"> <i className="fa-brands fa-facebook-f">
                                </i>
                                    Facebook
                                </a>
                            </div>

                            <div className="footer_container_right_social_google_maps">
                                <a href="https://maps.app.goo.gl/HAvezbExidXZKTZf8" target="_blank" rel="noreferrer">
                                    <i className="fa-solid fa-map-marker-alt"></i>
                                    Google
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </section>
    );
}

export default Contact;
