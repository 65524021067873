import React from 'react';
import '../Components_SCSS/Menu.scss';
import img1 from '../Assets/menu1.png';
import img2 from  '../Assets/menu2.png';

function Menu() {
    return (
        <section className='menu_restaurant'>
            <h4 className='menu_restaurant_title'>Nasze menu</h4>
            <p className='menu_restaurant_description'>Możliwość płatności kartą przy dowozach po uprzednim poinformowaniu kierowcy</p>
            <div className='menu_restaurant_box'>
                <img src={img1} alt=""/>
                <img src={img2} alt=""/>
            </div>
        </section>
    );
}

export default Menu;