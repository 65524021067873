import React from 'react'
import '../Components_SCSS/Guesthouse.scss'
import img2 from '../Assets/Pokój2.webp'
import img3 from '../Assets/Pokój3.webp'
import img4 from '../Assets/Pokoje/Pokoje(7).webp'



function Guesthouse() {
    return (
        <section className='guesthouse'>
            <h1 className='guesthouse_heading_text'>Pensjonat</h1>
            <div className='guesthouse_rooms'>
                <div className='guesthouse_rooms_box'>
                    <h2 className='rooms_heading_text'>Pokoje</h2>
                    <p className='rooms_heading_description'>Nasze pokoje to miejsce, gdzie znajdziesz spokój i
                        komfort. Z przyjemnością stworzyliśmy dla Ciebie przestrzeń, gdzie możesz w pełni wypocząć i
                        cieszyć się wygodą. Każdy pokój jest wyposażony w pełny węzeł sanitarny do prywatnej
                        dyspozycji.
                    </p>
                </div>
                <div className="rooms_box">
                    <div className="room_box">
                        <div className="room_box_img">
                            <img src={img2} alt=""/>
                        </div>
                        <div className="room_box_text">
                            <h3 className='room_box_text_heading'>Pokój 2 osobowy</h3>
                        </div>

                    </div>
                    <div className="room_box">
                        <div className="room_box_img">
                            <img src={img3} alt=""/>
                        </div>
                        <div className="room_box_text">
                            <h3 className='room_box_text_heading'>Pokój 3 osobowy</h3>
                        </div>

                    </div>
                    <div className="room_box">
                        <div className="room_box_img">
                            <img src={img4} alt=""/>
                        </div>
                        <div className="room_box_text">
                            <h3 className='room_box_text_heading'>Pokój rodzinny</h3>
                        </div>

                    </div>
                </div>
            </div>

            <div className="header_content_bottom">
                <h3 className='content_bottom_text'>Odkryj nasze ekskluzywne oferty specjalne, które dodadzą magii
                    Twojemu pobytowi.</h3>

                <div className="content_special_box">
                    <div className="content_special">
                        <div className="content_special_img">
                            <i className="fa-solid fa-temperature-three-quarters"></i>
                            <h4 className='special'>Sauna</h4>
                        </div>
                    </div>
                    <div className="content_special">
                        <div className="content_special_img">
                            <i className="fa-solid fa-water"></i>
                            <h4 className='special'>Balia</h4>
                        </div>
                    </div>
                    <div className="content_special">
                        <div className="content_special_img">
                            <i className="fa-solid fa-wifi"></i>
                            <h4 className='special'>Wifi</h4>
                        </div>
                    </div>
                    <div className="content_special">
                        <div className="content_special_img">
                            <i className="fa-solid fa-utensils"></i>
                            <h4 className='special'>Restauracja</h4>
                        </div>
                    </div>
                    <div className="content_special">
                        <div className="content_special_img">
                            <i className="fa-solid fa-square-parking"></i>
                            <h4 className='special'>Parking</h4>
                        </div>
                    </div>
                </div>

            </div>


        </section>
    )
}

export default Guesthouse