import './App.css';
import Home from "./Components_JSX/Home";
import Gallery from "./Components_JSX/Gallery";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Objekt from "./Components_JSX/Objekt";
import Room1 from "./Components_JSX/Sala1";
import Pokoje from "./Components_JSX/Pokoje";
import Room2 from "./Components_JSX/Sala2";

function App() {

    return (
        <>
            <div className="App container">

                <Router>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/galeria" element={<Gallery/>}/>
                        <Route path="/galeria/objekt" element={<Objekt/>}/>
                        <Route path="/galeria/sala-stacjonarna" element={<Room1/>}/>
                        <Route path="/galeria/sala-sezonowa" element={<Room2/>}/>
                        <Route path="/galeria/pokoje" element={<Pokoje/>}/>

                    </Routes>
                </Router>
            </div>

        </>
    )
        ;
}

export default App;
