import React from "react";
import ComponentGallery from "./Component_Gallery";

import img1 from '../Assets/Sala1/Sala_w(1).webp'
import img2 from '../Assets/Sala1/Sala_w(2).webp'
import img3 from '../Assets/Sala1/Sala_w(3).webp'
import img5 from '../Assets/Sala1/Sala_w(5).webp'
import img6 from '../Assets/Sala1/Sala_w(6).webp'
import img7 from '../Assets/Sala1/Sala_w(7).webp'
import img8 from '../Assets/Sala1/Sala_w(8).webp'
import img9 from '../Assets/Sala1/Sala_w(9).webp'
import img10 from '../Assets/Sala1/Sala_w(10).webp'
import img11 from '../Assets/Sala1/Sala_w(11).webp'
import img12 from '../Assets/Sala1/Sala_w(12).webp'
import img13 from '../Assets/Sala1/Sala_w(13).webp'
import img14 from '../Assets/Sala1/Sala_w(14).webp'
import img15 from '../Assets/Sala1/Sala_w(15).webp'
import img16 from '../Assets/Sala1/Sala_w(16).webp'




const images = [img13 , img5, img10, img6, img15,img2, img1, img16, img3, img11,img12, img14, img7, img8, img9];
function Room1() {

    return (
        <>
            <ComponentGallery img={images}/>
        </>
    )

}

export default Room1