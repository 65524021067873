import React from "react";
import logo from "../Assets/logo_sowka.webp";
import {Link as LinkRouter} from "react-router-dom";
import '../Components_SCSS/nav.scss';



function Nav1() {
    return (
        <nav className="nav">
            <div className="header_nav">
                <div className="header_logo">
                    <div className="logo">
                        <img className='header_logo_img' src={logo} alt="Logo_sówka"/>
                    </div>
                    <div className="logo_text">
                        <h1 className='logo_text_heading'>"Pod sówką"</h1>
                    </div>
                </div>

                <div className="mobile_menu">
                    <a href="tel: 601-419-879" className="header_button_desktop">Zadzwoń teraz!</a>
                    <li className='li_back_link'><LinkRouter to="/" smooth={true} duration={1000} className='back_link'>Powrót do strony głównej</LinkRouter></li>
                </div>
            </div>

        </nav>
    )

}

export default Nav1;