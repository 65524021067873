import React from "react";
import '../Components_SCSS/Restaurant.scss';
import img1 from '../Assets/Danie_domowe.webp';
import img2 from '../Assets/Pizza.webp';
import img3 from '../Assets/Burger.webp';


function Restaurant() {
    return (
        <section className='restaurant'>
            <div className='restaurant_heading'>
                <h1 className='restaurant_heading_text'>Restauracja</h1>
                <p className="restaurant_heading_description">Zapraszamy do naszej restauracji, gdzie serwujemy dania
                    domowe
                    oraz specjały kuchni
                    włoskiej, w tym pyszną pizzę i wiele innych smakołyków. Nasza kuchnia to połączenie
                    tradycyjnych receptur i świeżych składników, które gwarantują wyjątkowy smak i aromat
                    każdego dania. </p>
                <div className='restaurant_menu'>
                    <ul className="menu_list">
                        <li className="menu_list_element">Dania domowe</li>
                        <li className="menu_list_element color_menu">Pizza</li>
                        <li className="menu_list_element">Zupy</li>
                        <li className="menu_list_element color_menu">Sałatki</li>
                        <li className="menu_list_element">Menu dla dzieci</li>
                        <li className="menu_list_element color_menu">Napoje</li>
                    </ul>
                </div>
            </div>
            <div className='restaurant_content'>
                <div className='restaurant_content_box'>
                    <div className='restaurant_content_box_img'>
                        <img src={img1} alt="Danie domowe"/>
                        <img src={img2} alt="Pizza"/>
                        <img src={img3} alt="Burger"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Restaurant;