import React from "react";
import Header from "../Components_JSX/Header";
import Guesthouse from "../Components_JSX/Guesthouse";
import PartyRooms from "../Components_JSX/PartyRooms";
import Restaurant from "../Components_JSX/Restaurant";
import Contact from "../Components_JSX/Contact";
import Menu from "../Components_JSX/Menu";

function Home() {
    return(
        <div>
            <Header/>
            <Guesthouse/>
            <PartyRooms/>
            <Restaurant/>
            <Menu/>
            <Contact/>
        </div>
    )
}

export default Home;