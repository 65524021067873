import React from "react";
import '../Components_SCSS/Gallery.scss';
import Nav1 from "./nav1";
import img from '../Assets/Objekt/Objekt(2).webp';
import img1 from '../Assets/Stacjonarna.webp';
import img2 from '../Assets/Sezon.webp'
import img3 from '../Assets/Pokój2.webp';
import {Link} from "react-router-dom";


function Gallery() {

    return (
        <div className='gallery'>
            <Nav1/>
            <div className="gallery_content">
                <h1>Galeria</h1>
                <h3>Kliknij na kategorie zdjęć aby wyświetlić galerie</h3>
                <div className="gallery_content_photos">
                    <div className="gallery_content_photos_photo">
                        <Link to="objekt">
                        <h2>Zdjęcia obiektu</h2>
                        <img src={img} alt=""/>
                        </Link>
                    </div>
                    <div className="gallery_content_photos_photo">
                        <Link to="sala-stacjonarna">
                        <h2>Sala stacjonarna</h2>
                        <img src={img1} alt=""/>
                        </Link>
                    </div>
                    <div className="gallery_content_photos_photo">
                        <Link to="sala-sezonowa">
                        <h2>Sala sezonowa</h2>
                        <img src={img2} alt=""/>
                        </Link>
                    </div>
                    <div className="gallery_content_photos_photo">
                        <Link to="pokoje">
                        <h2>Pokoje</h2>
                        <img src={img3} alt=""/>
                        </Link>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Gallery;