import React from "react";
import '../Components_SCSS/Header.scss';
import {Link} from "react-scroll";
import Nav from "./nav";


function Header() {
    document.addEventListener('DOMContentLoaded', function() {
        const gallery = document.body.querySelector('.mobile_menu > li');
        if (window.innerWidth > 768) {
            gallery.classList.add('header_button_none');
        }
    });


    window.addEventListener("scroll", function () {
        const nav = document.querySelector(".nav")
        if (window.scrollY > 80 && window.innerWidth > 768) {
            nav.classList.add("scrolled");
        } else {
            nav.classList.remove("scrolled");
        }
    });

    return (
        <header className='header'>
            <Nav/>
            <div className="header_content">
                <div className="header_content_top">
                    <div className="content_text">
                        <h2 className='content_text_welcome'>Witamy w Pensjonacie<br></br>
                            "Pod Sówką"!</h2>
                        <h3 className='content_text_heading'>Zapewniamy komfortowy<br></br>
                            wypoczynek oraz <br></br> smaczne jedzenie</h3>
                        <p className='content_text_description'>Nasz pensjonat oferuje niezapomniane doświadczenia
                            zarówno w zakresie zakwaterowania, jak i kulinarnej podróży. Położony w malowniczym mieście
                            Gostynin, nasz pensjonat stanowi idealną bazę wypadową do odkrywania uroków tej urokliwej
                            okolicy.</p>
                        <div className="content_link">
                            <Link to="guesthouse" href='#' smooth={true} duration={1000}
                                  className="content_link_button_first">Pensjonat</Link>
                            <Link to="restaurant" href='#' smooth={true} duration={1000}
                                  className="content_link_button_second">Restauracja</Link>
                        </div>
                    </div>
                    <div className="content_img">
                        <div className="header_info">
                            <div className="header_info_phone">
                                <i className="fas fa-phone"></i>
                                <a href="tel: 601-419-879">601-419-879</a>
                                <p>lub</p>
                                <a href="tel: 535-519-309">535-519-309</a>
                            </div>
                            <div className="header_info_location">
                                <i className="fas fa-map-marker-alt"></i>
                                <p>ul. 18-go Stycznia 20,<br></br> 09-500 Gostynin</p>
                            </div>
                            <div className="header_info_mail">
                                <i className="fas fa-envelope"></i>
                                <p>
                                    <a href="mailto:txt@gmail.com">
                                        <p>noclegi.pod.sowka@onet.pl</p>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </header>
    );
}

export default Header;
