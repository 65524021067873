import React from "react";
import ComponentGallery from "./Component_Gallery";
import img1 from '../Assets/Pokoje/Pokoje(1).webp'
import img2 from '../Assets/Pokoje/Pokoje(2).webp'
import img3 from '../Assets/Pokoje/Pokoje(3).webp'
import img4 from '../Assets/Pokoje/Pokoje(4).webp'
import img5 from '../Assets/Pokoje/Pokoje(5).webp'
import img6 from '../Assets/Pokoje/Pokoje(6).webp'
import img7 from '../Assets/Pokoje/Pokoje(7).webp'
import img8 from '../Assets/Pokoje/Pokoje(8).webp'
import img9 from '../Assets/Pokoje/Pokoje(9).webp'
import img10 from '../Assets/Pokoje/Pokoje(10).webp'
import img11 from '../Assets/Objekt/Nowe(16).webp'

const images = [img1, img2, img3, img4, img5, img6, img11, img7, img8, img9, img10];

function Rooms() {

    return (
        <>
            <ComponentGallery img={images}/>
        </>
    )

}

export default Rooms