import React from "react";
import ComponentGallery from "./Component_Gallery";
import img1 from '../Assets/Sala2/Sala_z(1).webp'
import img2 from '../Assets/Sala2/Sala_z(2).webp'
import img3 from '../Assets/Sala2/Sala_z(3).webp'
import img5 from '../Assets/Sala2/Sala_z(5).webp'
import img6 from '../Assets/Sala2/Sala_z(6).webp'
import img7 from '../Assets/Sala2/Sala_z(7).webp'
import img8 from '../Assets/Sala2/Sala_z(8).webp'
import img9 from '../Assets/Objekt/Objekt(3).webp'
import img10 from '../Assets/Objekt/Objekt(12).webp'


const images = [img2, img1, img3, img5, img6, img7, img8, img9, img10];



function Room2() {

    return (
        <>
            <ComponentGallery img={images}/>
        </>
    )

}

export default Room2