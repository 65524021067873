import React from "react";
import logo from "../Assets/logo_sowka.webp";
import {Link} from "react-scroll";
import '../Components_SCSS/nav.scss';
import {Link as LinkRouter} from "react-router-dom";


function Nav () {

    return (
        <nav className="nav">
            <div className="header_nav">
                <div className="header_logo">
                    <div className="logo">
                        <img className='header_logo_img' src={logo} alt="Logo_sówka"/>
                    </div>
                    <div className="logo_text">
                        <h1 className='logo_text_heading'>"Pod sówką"</h1>
                    </div>
                </div>
                <div className="header_menu">

                    <ul className='menu'>
                        <li><Link to="header" href='#' smooth={true} duration={1000}>Start</Link></li>
                        <li><Link to="guesthouse" href='#' smooth={true} duration={1000}>Pensjonat</Link></li>
                        <li><Link to="party_rooms" href='#' smooth={true} duration={1000}>Zaplanuj przyjęcie</Link></li>
                        <li><Link to="restaurant" href='#' smooth={true} duration={1000}>Restauracja</Link></li>
                        <li><Link to="menu_restaurant" href='#' smooth={true} duration={1000}>Menu</Link></li>
                        <li><LinkRouter to="/galeria" href='#'>Galeria</LinkRouter></li>
                        <li><Link to="contact" href='#' smooth={true} duration={1000}>Kontakt</Link></li>
                    </ul>
                </div>
                <div className="mobile_menu">
                    <a href="tel: 601-419-879" className="header_button_desktop">Zadzwoń teraz!</a>
                    <li className='galeria_mobile'><LinkRouter to="/galeria" className='galeria_side' href='#'>Galeria</LinkRouter></li>
                </div>
            </div>

        </nav>

    )
}

export default Nav;




