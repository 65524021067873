import React from "react";
import ComponentGallery from "./Component_Gallery";
import img1 from '../Assets/Objekt/Objekt(2).webp'
import img2 from '../Assets/Objekt/Objekt(1).webp'
import img3 from '../Assets/Objekt/Objekt(16).webp'
import img5 from '../Assets/Objekt/Objekt(3).webp'
import img6 from '../Assets/Objekt/Objekt(11).webp'
import img7 from '../Assets/Objekt/Objekt(12).webp'
import img8 from '../Assets/Objekt/Objekt(14).webp'
import img9 from '../Assets/Objekt/Objekt(7).webp'
import img10 from '../Assets/Objekt/Objekt(8).webp'
import img11 from '../Assets/Objekt/Objekt(9).webp'
import img12 from '../Assets/Objekt/Objekt(13).webp'
import img13 from '../Assets/Objekt/Objekt(5).webp'
import img14 from '../Assets/Objekt/Objekt(10).webp'
import img15 from '../Assets/Objekt/Objekt(6).webp'
import img17 from '../Assets/Objekt/Nowe(1).webp'
import img18 from '../Assets/Objekt/Nowe(2).webp'
import img19 from '../Assets/Objekt/Nowe(3).webp'
import img20 from '../Assets/Objekt/Nowe(4).webp'
import img21 from '../Assets/Objekt/Nowe(5).webp'
import img22 from '../Assets/Objekt/Nowe(6).webp'
import img23 from '../Assets/Objekt/Nowe(7).webp'
import img24 from '../Assets/Objekt/Nowe(8).webp'
import img25 from '../Assets/Objekt/Nowe(9).webp'
import img26 from '../Assets/Objekt/Nowe(10).webp'
import img27 from '../Assets/Objekt/Nowe(11).webp'
import img28 from '../Assets/Objekt/Nowe(12).webp'
import img29 from '../Assets/Objekt/Nowe(13).webp'
import img30 from '../Assets/Objekt/Nowe(14).webp'
import img31 from '../Assets/Objekt/Nowe(15).webp'

const images = [img1, img2, img3, img5, img6, img7, img8, img9, img10, img18, img19, img13, img14, img15, img17, img11, img12, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31]

function Objekt() {

    return (
        <>
            <ComponentGallery img={images}/>
        </>
    )

}

export default Objekt